import React from 'react'
import { Link } from 'react-router-dom';
import Logo from "../assets/img/logo.png";

export default function MainFooter() {
  return (
    <>
         <footer className="default-padding footer ">
        <div className="container">
            <div className="f-items">
                <div className="row">
                    <div className="col-lg-4 col-md-6 item">
                        <div className="f-item">
                            <img src={Logo} alt="Logo" width="200" />
                            <div className="d-flex mt-3 gap-3">
                                <Link className="btn download-app-img btn-md p-0 rounded-pill overflow-hidden" href="#"><img
                                        src="./assets/img/appstore.png" alt="" className="w-100 " /></Link>
                                <Link className="btn download-app-img btn-md p-0 rounded-pill overflow-hidden" href="#"><img
                                        src="./assets/img/googleplay.png" alt="" className="w-100 " /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 item">
                        <div className="f-item link">
                            <h4 className="text-white">Quick LInk</h4>
                            <ul>
                                <li>
                                    <Link to="/terms-and-condition"><i className="fas fa-angle-right"></i> Terms & Condition</Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy"><i className="fas fa-angle-right"></i> Privacy Policy</Link>
                                </li>
                               
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 item">
                        <div className="f-item twitter-widget">
                            <h4 className="text-white">Bussiness Info</h4>

                            <div className="address">
                                <ul>
                                    <li>
                                        <div className="icon">
                                            <i className="fas fa-map"></i>
                                        </div>
                                        <div className="info">
                                            <h5>Address:</h5>
                                            <span>62/8 Marla Ground floor
                       sector 11 Gurgaon
                      <br /> Haryana India 122001</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fas fa-envelope"></i>
                                        </div>
                                        <div className="info">
                                            <h5>Email:</h5>
                                            <span>Info@scanmeplus.in</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fas fa-phone"></i>
                                        </div>
                                        <div className="info">
                                            <h5>Phone:</h5>
                                            <span>+91-92107-92107</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}
