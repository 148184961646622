import { Button } from "primereact/button";
import React from "react";

export default function PrimaryButton({
  label,
  onChange,
  style,
  icon,
  iconPos,
  onClick,
  className,
  outlined,
}) {
  return (
    <Button
      className={className}
      label={label}
      rounded
      onClick={onClick}
      icon={icon}
      iconPos={iconPos}
      style={style}
      onChange={onChange}
      outlined={outlined}
    />
  );
}
