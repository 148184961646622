import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./styles.scss";
import Home from "./views/home";
import PageNotFound from "./views/PageNotFound";
import { LandingPage } from "./views/landing-page";
import TermsCondition from "./views/others-pages/Terms&Condition";
import PrivacyPolicy from "./views/others-pages/PrivacyPolicy";
import RefundPolicy from "./views/others-pages/RefundPolicy";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/verification" element={<Home />} />
          <Route path="/terms-and-condition" element={<TermsCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
