import axios from "axios";
import { BASE_URL } from "../constants";
const api = async (method, urlEndPoint, data = {}, params = {}, header = "application/json") => {
    try {
        let headers = {
            "Content-Type": header,
        };
      
        let response = await axios({
            method,
            url: BASE_URL + urlEndPoint,
            data,
            headers,
            params,
        });

        let res = response.data;
        return res;
    } catch (error) {
        if (error.response && error.response.status === 401) {
           
        }
        let res = error?.response ? error.response.data : error.toString();
        return res;
    }
};
export default api;
