import React from "react";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <img src={logo} alt="ScanMe Logo" className="logo" />
      <h1>404</h1>
      <p>Page Not Found</p>
      <Link to="/" className="home-link">
        Go to Home
      </Link>
    </div>
  );
};

export default PageNotFound;
