import React from "react";
import { Link } from "react-router-dom";
import PlayStoreIcon from "../../assets/img/appstore.png";
import GooglePlayIcon from "../../assets/img/googleplay.png";
import BannerImg from "../../assets/img/banner/Scan me.png";
import WaveShape from "../../assets/img/shape/2.png";
import AboutMocupImg from "../../assets/img/about/Phone_mockup_3.png";
import PortfolioImg from "../../assets/img/about/about.png";
import TopHeader from "../../layout/TopHeader";
import MainFooter from "../../layout/mainFooter";
import Slider from "react-slick";
import HomepageData from "./homeContent";
import PrimaryButton from "../../components/buttons/button";

export const LandingPage = () => {
  const { ServiceContent } = HomepageData();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <TopHeader />
      {/* Banner start */}
      <div className="banner-area content-double transparent-nav bg-gradient-theme text-light small-text">
        <div className="box-table">
          <div className="box-cell">
            <div className="container">
              <div className="double-items">
                <div className="row align-center">
                  <div
                    className="col-lg-5 left-info simple-video"
                    data-aos="fade-right"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="500"
                  >
                    <div
                      className="content"
                      data-animation="animated fadeInUpBig"
                    >
                      <h1>Connect Instantly, Resolve Quickly</h1>
                      <p className="h5">
                        Allow people to contact you in case of any issue with
                        your parked vehicle.
                      </p>
                      <div className="d-flex gap-3">
                        <Link
                          className="btn download-app-img btn-md p-0 overflow-hidden"
                          href="#"
                        >
                          <img src={PlayStoreIcon} alt="" className="w-100 " />
                        </Link>
                        <Link
                          className="btn download-app-img btn-md p-0 overflow-hidden"
                          href="#"
                        >
                          <img src={GooglePlayIcon} alt="" className="w-100 " />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-7 right-info width-max"
                    data-aos="fade-left"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="500"
                  >
                    <img src={BannerImg} alt="Thumb" width="500" />
                  </div>
                </div>
              </div>
            </div>
            <div className="wavesshape">
              <img src={WaveShape} alt="Shape" />
            </div>
          </div>
        </div>
      </div>

      {/* banner end */}
      {/* about start */}
      <div id="how-its-works" className="about-area default-padding-top">
        <div className="container">
          <div className="row align-items-center about-items">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <img src={AboutMocupImg} alt="Shape" width="500" />
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className="about-content">
                <h2>How it works ?</h2>
                <h6 className="font-weight-bold">Register Your Vehicle:</h6>
                <ul>
                  <li>
                    Download the "Scan me plus" app and create an account. Enter
                    your vehicle details and Buy a membership plan.
                  </li>
                </ul>
                <h6 className="font-weight-bold">Apply the Sticker:</h6>
                <ul>
                  <li>
                    Get your sticker from Delivery or our executives, and place
                    the QR code sticker on your vehicle. Ensure the sticker is
                    clean and easily scannable.
                  </li>
                </ul>
                <h6 className="font-weight-bold">Scan and Connect:</h6>
                <ul>
                  <li>
                    If someone needs to contact you about your vehicle, they can
                    simply scan the QR code using our app or with their
                    smartphone scanner.
                  </li>
                </ul>
                <h6 className="font-weight-bold">Receive Alerts & Call:</h6>
                <ul>
                  <li>
                    Get instant Texts or Calls through the app when someone
                    scans your QR code and sends you the call or text.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* about end */}
      {/* portfolio start */}
      <div className="companies-area default-padding" id="portfolio">
        <div className="container">
          <div className="row">
            {ServiceContent.map((slide) => {
              return (
                <div className="col-6 col-lg-3 px-0" key={slide}>
                  <img src={slide.img} alt="Shape" className="w-100" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* portfolio end */}

      {/* pricing start */}
      <div id="plans" className="pricing-area default-padding bottom-less">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h2>Our Plans</h2>
                <p className="h5">Choose the Perfect Plan for Your Needs</p>
              </div>
            </div>
          </div>
          <div className="pricing pricing-simple text-center">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="pricing-item">
                  <div className="pricing-header">
                    <h4>3 month / Per vehicle</h4>
                    <h2>
                      <sup>₹</sup>60
                    </h2>
                    <small>(Taxes excluded)</small>
                  </div>
                  <ul>
                    <li>
                      <i className="fas fa-check"></i> Get a Personalized
                      Sticker
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Instant Safety Alerts
                    </li>
                    <li>
                      <i className="fas fa-check"></i> In App Calling Feature
                    </li>
                    <li>
                      <i className="fas fa-check"></i> User-Friendly Call
                      History
                    </li>
                    <li className="footer">
                      <PrimaryButton
                        label="Buy This Plan"
                        className="btn circle btn-theme border btn-sm "
                        outlined
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-item active">
                  <div className="pricing-header">
                    <h4> 6 month / Per vehicle</h4>
                    <h2>
                      <sup>₹</sup>120
                    </h2>
                    <small className="text-white">(Taxes excluded)</small>
                  </div>
                  <ul>
                    <li>
                      <i className="fas fa-check"></i> Get a Personalized
                      Sticker
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Instant Safety Alerts
                    </li>
                    <li>
                      <i className="fas fa-check"></i> In App Calling Feature
                    </li>
                    <li>
                      <i className="fas fa-check"></i> User-Friendly Call
                      History
                    </li>
                    <li className="footer">
                      <Link
                        className="btn circle btn-theme effect btn-sm"
                        href="#"
                      >
                        Buy This Plan
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-item">
                  <div className="pricing-header">
                    <h4>Yearly / Per vehicle</h4>
                    <h2>
                      <sup>₹</sup>240
                    </h2>
                    <small>(Taxes excluded)</small>
                  </div>
                  <ul>
                    <li>
                      <i className="fas fa-check"></i> Get a Personalized
                      Sticker
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Instant Safety Alerts
                    </li>
                    <li>
                      <i className="fas fa-check"></i> In App Calling Feature
                    </li>
                    <li>
                      <i className="fas fa-check"></i> User-Friendly Call
                      History
                    </li>
                    <li className="footer">
                      <Link
                        className="btn circle btn-theme border btn-sm"
                        href="#"
                      >
                        Buy This Plan
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* pricing end */}

      {/* contact start */}
      <div id="contact" className="contact-us-area default-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h2>Contact Us</h2>
                <p className="h5">
                  Have Questions or Need Support? Contact Us Anytime for
                  Assistance.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-6 address"
              data-aos="fade-right"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className="address-items">
                <h4>Our Office Address</h4>
                <ul className="info">
                  <li>
                    <i className="fas fa-map-marked-alt"></i>
                    <span>
                      62/8 Marla Ground floor
                      <br /> sector 11 Gurgaon
                      <br /> Haryana India 122001
                    </span>
                  </li>
                  <li>
                    <i className="fas fa-phone"></i>
                    <span>+91-92107-92107</span>
                  </li>
                  <li>
                    <i className="fas fa-envelope-open"></i>
                    <span>Info@scanmeplus.in</span>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-lg-6 contact-form"
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <h2>Send us your Query.</h2>
              <form
                action="assets/mail/contact.php"
                method="POST"
                className="contact-form"
              >
                <div className="col-lg-12">
                  <div className="row">
                    <div className="form-group">
                      <input
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Name"
                        type="text"
                      />
                      <span className="alert-error"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="form-group">
                      <input
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email*"
                        type="email"
                      />
                      <span className="alert-error"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="form-group">
                      <input
                        className="form-control"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                        type="text"
                      />
                      <span className="alert-error"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="form-group comments">
                      <textarea
                        className="form-control"
                        id="comments"
                        name="comments"
                        placeholder="Tell Us About Query *"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <button type="submit" name="submit" id="submit">
                      Send Message <i className="pi pi-send"></i>
                    </button>
                  </div>
                </div>
                <div className="col-lg-12 alert-notification">
                  <div id="message" className="alert-msg"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* contact end */}
      <MainFooter />
    </>
  );
};
